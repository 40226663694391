import React from 'react';
import CookieConsent from "react-cookie-consent";

function CookieConsentBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Understand"
      cookieName="myAppNameCookieConsent"
      style={{ 
        background: "#2B373B",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        padding: "10px",
      }}
      buttonStyle={{ 
        color: "#FFF", 
        fontSize: "13px", 
        backgroundColor: "#20c997",
        marginLeft: "10px",
        borderRadius: "16px",
        padding: "5px 20px",
      }}
      expires={150} // This will set the cookie to expire after 150 days
    >
      This website uses cookies to enhance the user experience. {" "}
      <a
        data-bs-toggle="modal"
        data-bs-target="#terms-policy"
        href="#terms-policy"
      >
        Learn more
      </a>
    </CookieConsent>
  );
}

export default CookieConsentBanner;