import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(HttpBackend) // Загрузка переводов через http (например, из публичной папки)
    //.use(LanguageDetector) // Определяет язык пользователя
    .use(initReactI18next) // Инициализация i18next
    .init({
        fallbackLng: 'en', // Основной язык
        debug: false, // Включить для отладки
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Путь к переводам
        },
    });

export default i18n;