import React from "react";
import { useTranslation } from 'react-i18next';
import resumeFile from "../documents/resume.pdf";
import AOS from 'aos';
import { useEffect } from 'react';

const Resume = ({ classicHeader, darkTheme }) => {

  useEffect(() => {
    AOS.init();
  }, []);

  const { t } = useTranslation();
  const expDetailsKeys = [1, 2, 3, 4];
  const skills = [
    {
      name: "Web Design",
      percent: 81,
    },
    {
      name: "Bootstrap",
      percent: 99,
    },
    {
      name: "HTML/CSS",
      percent: 94,
    },
    {
      name: "PHP/JS/JQ",
      percent: 90,
    },
    {
      name: "Python",
      percent: 70,
    },
    {
      name: "Axure/Figma",
      percent: 95,
    },
    {
      name: "Photoshop/Illustrator/Sketch",
      percent: 82,
    },
    {
      name: "Laravel",
      percent: 80,
    },
    {
      name: "Project management",
      percent: 100,
    },
    {
      name: "WordPress",
      percent: 98,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div data-aos="zoom-in" className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-secondary opacity-1" : "text-light opacity-4")
            }
          >
            {t('resume_titleBg')}
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            {t('resume_title')}
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <h2 className={"text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")}>
          {t('resume_expTitle')}
        </h2>
        <div className="row gx-5">
          {expDetailsKeys.map((key, index) => (
              <div className="col-md-6" key={index}>
                <div className={"bg-white rounded p-4 mb-4 " + (darkTheme ? "bg-dark" : "bg-white border")}>
                  <p className="badge bg-primary text-2 fw-400">
                    {t(`resume_exp_${key}_yearRange`)}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {t(`resume_exp_${key}_title`)}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {t(`resume_exp_${key}_place`)}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {t(`resume_exp_${key}_desc`)}
                  </p>
                </div>
              </div>
          ))}
        </div>

        {/* My Skills */}
        <h2 className={"text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")}>
          {t('resume_skillsTitle')}
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            {t('download_cv')}
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
