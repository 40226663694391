import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              GDPR Compliance Statement
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">


            <p><strong>Introduction</strong>: Our website prioritizes the privacy and security of our users. In compliance with the General Data Protection Regulation (GDPR), we've implemented practices to ensure the protection of your personal data.</p>

            <div className="mb-3">
                <h4 className="text-white">1. Data Collection and Purpose</h4>
                <ul>
                    <li><strong>Consent</strong>: Before gathering personal data, we ensure to obtain explicit user consent. Users can withdraw their consent at any time.</li>
                    <li><strong>Data Minimization</strong>: We only collect data essential for our services, ensuring it's relevant and limited to what's necessary.</li>
                </ul>
            </div>

            <div className="mb-3">
                <h4 className="text-white">2. User Rights</h4>
                <ul>
                    <li><strong>Right to Erasure</strong>: Users have the option to request their data be deleted from our database.</li>
                    <li><strong>Data Access</strong>: Any user can request a full report of their stored data.</li>
                    <li><strong>Data Rectification</strong>: Any inaccuracies in user data can be amended upon request.</li>
                    <li><strong>Data Restriction</strong>: Users can limit how their data is used.</li>
                    <li><strong>Data Portability</strong>: Upon request, users can receive their data in a commonly used and machine-readable format or have it transferred to another organization.</li>
                </ul>
            </div>

            <div className="mb-3">
                <h4 className="text-white">3. Data Security</h4>
                <ul>
                    <li><strong>Protection Measures</strong>: Our platform employs state-of-the-art security measures to prevent unauthorized access, alteration, or deletion of data.</li>
                    <li><strong>Breach Notification</strong>: If a data breach occurs, affected users and authorities will be informed within 72 hours.</li>
                </ul>
            </div>

            <div className="mb-3">
                <h4 className="text-white">4. Cookies and Tracking</h4>
                <ul>
                    <li><strong>Consent</strong>: Before using tracking or advertising cookies, we seek user consent.</li>
                    <li><strong>Control</strong>: Users can manage, restrict, or opt out of cookie usage.</li>
                </ul>
            </div>

            <div className="mb-3">
                <h4 className="text-white">5. Third Parties</h4>
                <p>We do not share data with third parties without user consent, unless legally obligated.</p>
            </div>

            <p>For further details or inquiries regarding our data practices and GDPR compliance, please contact us.</p>


          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
