import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { useTranslation } from 'react-i18next';
import AOS from 'aos';

const Portfolio = ({ classicHeader, darkTheme }) => {

  const { t } = useTranslation();
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    WP: "WordPress",
    // LARAVEL: "Laravel",
    // WEBSITE: "Website",
    // DASHBOARD: "Dashboard",
  };

  const projectsData = [
    {
      title: "Houzer",
      projectInfo:
        "Developed a sleek, user-friendly website for a leading real estate investment company. Designed to be fully responsive, it ensures navigation on all devices. The platform is built with an emphasis on simplicity and ease of use, boosting client interaction and conversions.",
      client: "Houzer LTD",
      technologies: "HTML5, CSS3, PHP, WordPress, Bootstrap 5",
      industry: "Investments",
      date: "January 15, 2023",
      url: {
        name: "houzer.uk",
        link: "https://houzer.uk/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/houzer.jpg",
      sliderImages: [
        "images/projects/houzer-1.jpg",
        "images/projects/houzer-2.jpg",
      ],
      categories: [filters.WP],
    },
    {
      title: "AlterEgo",
      projectInfo:
        "I developed a website specifically for a branch of a cosmetics company. Presented their unique products using striking visuals.",
      client: "Strata 108",
      technologies: "HTML5, CSS3, PHP, WP, Bootstrap 5, Magento",
      industry: "Cosmetics",
      date: "April 10, 2018",
      url: {
        name: "alteregoitaly.com",
        link: "https://www.alteregoitaly.com/ae_en/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/alterego.jpg",
      sliderImages: [
        "images/projects/alterego-1.jpg",
      ],
      categories: [],
    },
    {
      title: "Client Dashboard",
      projectInfo:
        "Developed a user-centric Client Dashboard for a real estate investment firm. Integrated advanced security features to protect financial data. Enhanced user experience through intuitive design and navigation. Supported multi-device compatibility for on-the-go access.",
      client: "Houzer LTD",
      technologies: "HTML5, CSS3, PHP, Bootstrap 5, Laravel 8",
      industry: "Investments",
      date: "March 10, 2023",
      url: {
        name: "app.houzer.uk",
        link: "https://app.houzer.uk/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/app-houzer.jpg",
      sliderImages: [
        "images/projects/app-houzer-1.jpg",
        "images/projects/app-houzer-2.jpg",
      ],
      categories: [],
    },
    {
      title: "Status-M",
      projectInfo:
        "Participated in creating a website for a Montenegrin property company, offering coastal properties and value propositions.",
      client: "Status-M company",
      technologies: "HTML5, CSS3, PHP, WordPress, Bootstrap 5",
      industry: "Real estate",
      date: "July 16, 2019",
      url: {
        name: "statusm.estate",
        link: "https://statusm.estate/en/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/statusm.jpg",
      sliderImages: [
        "images/projects/statusm-1.jpg",
      ],
      categories: [filters.WP],
    },
    {
      title: "Sqarico",
      projectInfo:
        "Developed a website for SQuarico, a UK property investment company. Their unique model lets small investors join in and profit. The site showcases properties and explains the shared profit approach. Simple, transparent, and built for everyone to invest.",
      client: "Sqarico company",
      technologies: "HTML5, CSS3, PHP, WP, Bootstrap 5",
      industry: "Investments",
      date: "September 23, 2022",
      url: {
        name: "squarico.com",
        link: "https://squarico.com/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/squarico.jpg",
      sliderImages: [
        "images/projects/squarico-1.jpg",
        "images/projects/squarico-2.jpg",
        "images/projects/squarico-3.jpg",
      ],
      categories: [filters.WP],
    },
    /*
    {
      title: "Project Title 6",
      projectInfo:
        "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      client: "Ruby Clinton",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      date: "July 16, 2019",
      url: {
        name: "www.example.com",
        link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/project-6.jpg",
      sliderImages: [
        "images/projects/project-1.jpg",
        "images/projects/project-5.jpg",
      ],
      categories: [filters.WEBSITE],
    },
    {
      title: "Project Title 7",
      projectInfo:
        "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
      client: "Ruby Clinton",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry: "Art & Design",
      date: "July 16, 2019",
      url: {
        name: "www.example.com",
        link: "https://www.example.com",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/project-7.jpg",
      sliderImages: [
        "images/projects/project-1.jpg",
        "images/projects/project-5.jpg",
      ],
      categories: [filters.LARAVEL, filters.WEBSITE],
    },
    */
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div data-aos="fade-right" className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              {t('portfolio_titleBg')}
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              {t('portfolio_title')}
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                {t('portfolio_all')}
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/* <span className="text-light">Category</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
